.toggle-sidepanel {
  visibility: hidden;
  position: fixed;
  top: 140px;
  right: 0;
  cursor: pointer;
  text-decoration: none;
  color: white;
  overflow: hidden;
  transition: width ease-in .4s;
  width: 100px;
  max-width: 95%;
  z-index: 3;

  &:hover {
    width: 130px;
  }

  span {
    border-color: white;
    border-style: solid;
    white-space: nowrap;
    border-width: 2px 0;
    position: relative;
    display: block;
    padding: 0.75em 0 0.75em 2em;
    background-color: $bg-darker;

    &:before {
      border-style: solid;
      border-color: white;
      border-width: 0;
      content: "";
      display: inline-block;
      border-bottom-width: 2px;
      border-right-width: 2px;
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
      -webkit-transform-origin: 0 100%;
      -moz-transform-origin: 0 100%;
      -ms-transform-origin: 0 100%;
      -o-transform-origin: 0 100%;
      transform-origin: 0 100%;
      position: absolute;
      top: -50%;
      bottom: 50%;
      left: 1.6em;
      right: 0;
      background-color: $green;
    }
  }

  @media screen and (max-width: 1100px) {
    visibility: visible;
    z-index: 1;
  }
}