.container-fluid {
  position: relative;
  width: 100%;
  height: 100vh;

  .split.left::before,
  .split.right::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .split.left {
    left: 0;
    background-image: url('../../../public/img/home-left-image.jpg');

    &::before {
      background-color: $left-bg-color;
    }

    .btn:hover {
      background-color: $left-hover-color;
      border-color: $left-hover-color;
    }
  }

  .split.right {
    right: 0;
    background-image: url('../../../public/img/home-right-image.jpg');

    &::before {
      background-color: $right-bg-color;
    }

    .btn:hover {
      background-color: $right-hover-color;
      border-color: $right-hover-color;
    }
  }

  .split.left, .split.left::before,
  .split.right, .split.right::before {
    transition: all 1s ease-in-out;
  }

  h1 {
    color: #fff;
    font-size: 3em;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 500px) {
      font-size: 2em;
    }
  }

  .btn {
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    border: 2px solid #fff;
    padding: 1.5em;
    font-size: 1.1em;
    width: 15em;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    @media screen and (max-width: 500px) {
      width: 12em;
      padding: 1.2em;
      font-size: 1em;
    }
  }
}

@media screen and (min-width: 1000px) {
  .split {
    position: absolute;
    width: 50%;
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .hover-left .left,
  .hover-right .right {
    width: 75%;
  }

  .hover-right .left,
  .hover-left .right {
    width: 25%;
  }
}

@media screen and (max-width: 999px) {
  .split {
    position: relative;
    height: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .btn {
    top: 40%;
  }

  .hover-top .left,
  .hover-bottom .right {
    height: 75%;
  }

  .hover-bottom .left,
  .hover-top .right {
    height: 25%;
  }
}

#credit {
  font-family: $Calibri-Light;
  font-size: .9em;
  position: fixed;
  bottom: 20px;
  right: 0;
  left: 0;
  text-align: center;
  color: white;
}