.pagination {
  display: flex;
  justify-content: center;
  margin: 20px auto;

  .page-item {
    border: 1px solid #d7d3d3;
    background-color: white;
    height: 35px;
    line-height: 35px;
    cursor: pointer;

    &:hover {
      background-color: #f3f3f3;
    }

    + .active {
      background-color: $green-darker;

      .page-link {
        color: white;
      }
    }

    .page-link {
      text-decoration: none;
      padding: 6px 12px;
      color: $green-darker;
    }
  }

  .previous {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .next {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }

  .disabled {
    cursor: default;

    .page-link {
      color: #8b8b8b;
    }

    &:hover {
      background-color: white;
    }
  }
}
