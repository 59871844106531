#container-mangas {
  .block-manga {
    position: relative;
    display: flex;
    background-color: rgba(0, 0, 0, .28);
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin: 20px auto;
    overflow: hidden;
    transition: all ease-in 1s;
    min-height: 200px;
    width: 70%;

    img {
      transition: all ease-in 1s;
      max-height: 300px;
      width: 160px;
      height: auto;
    }

    .content {
      align-self: center;
      padding: 30px;

      h1 {
        transition: all ease-in 1s;
        font-size: 1.5em;
      }

      h4 {
        transition: all ease-in 1s;
        font-size: 1em;
        margin: 20px auto;
        font-weight: 400;
      }

      p {
        transition: all ease-in 1s;
        margin-top: 20px;
        font-family: $Calibri-Light;
        font-size: .9em;
        text-align: justify;
      }

      .tiny-content {
        margin-top: 20px;

        span, button {
          font-family: $JosefinSans-SemiBold;
          font-size: 1em;
          border-radius: 5px;
          padding: 10px 20px;
        }

        span {
          display: inline-block;
          background-color: rgba(0, 0, 0, .38);
        }

        button {
          transition: all ease-in .2s;
          background-color: $green;
          color: white;
          margin-left: 10px;
          border: none;
          cursor: pointer;

          &:hover {
            background-color: $green-darker;
            text-decoration: underline;
          }
        }
      }
    }

    &.increase-container {
      cursor: default;
      transition: all ease-in 1s;
      width: 85%;
      min-height: 400px;

      img {
        transition: all ease-in 1s;
        width: 260px;
        align-self: center;
        margin-left: 30px;
      }

      h1 {
        font-size: 1.7em;
      }

      h4 {
        font-size: 1.2em;
      }

      p {
        font-size: 1.1em;
      }
    }

    @media screen and (max-width: 1200px) {
      width: 80%;

      &.increase-container {
        width: 95%;
      }
    }

    @media screen and (max-width: 900px) {
      width: 90%;

      &.increase-container h1 {
        font-size: 1.5em;
      }
    }
  }

  @media screen and (max-width: 700px) {
    display: grid;
    grid-template-columns: 1fr;
    padding: 20px;
    gap: 20px;

    .block-manga {
      display: block;
      width: 100%;
      height: 340px;
      margin: 0;
      padding: 20px;
      text-align: center;

      img {
        height: 240px;
        width: auto;
      }

      .content {
        padding: 0;

        h1 {
          margin-top: 20px;
          font-size: 1.4em;
        }

        h4, p {
          visibility: hidden;
        }
      }

      &.increase-container {
        height: 46em;
        width: 100%;

        img {
          height: 340px;
          max-height: 340px;
          width: auto;
          margin: 0;
        }

        .content {
          h4, p {
            visibility: visible;
          }

          h4 {
            font-size: 1.1em;
          }

          p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 10;
            overflow: hidden;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .block-manga {
      img {
        height: 220px;
        width: auto;
      }

      .content {
        h1 {
          font-size: 1.3em;
        }
      }

      &.increase-container {
        height: 51em;

        img {
          height: 320px;
          max-height: 320px;
          width: auto;
        }

        .content {
          h4 {
            font-size: 1em;
          }
        }
      }
    }
  }
}