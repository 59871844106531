$JosefinSans-Medium: "josefin-sans-medium", sans-serif;
$JosefinSans-Light: "josefin-sans-light", sans-serif;
$JosefinSans-Regular: "josefin-sans-regular", sans-serif;
$JosefinSans-Bold: "josefin-sans-bold", sans-serif;
$JosefinSans-SemiBold: "josefin-sans-semi-bold", sans-serif;
$Calibri-Light: "calibri-light", sans-serif;
$Arial: Arial, sans-serif;

$bg-darker: #1c1c1c;
$bg-medium: #2c3034;
$bg-color: #949494;
$bg-light: #f3f3f3;
$green-acid: #31ff31;
$green: #03c803;
$green-darker: #11a911;
$red-error: #DD1717;

$header-succes-modal: #28af60;
$header-error-modal: #e74d3c;
$header-error-modal_hover: #d74434;
$grey-button-modal: #e9e9ed;
$grey-button-modal_hover: #d9d9da;

$left-bg-color: rgba(87, 84, 236, .7);
$left-hover-color: rgb(87, 84, 236);
$right-bg-color: rgba(43, 43, 43, .8);
$right-hover-color: rgb(43, 43, 43);