#sort-genres {
  text-align: center;
  margin: 28px auto 20px auto;
  padding: 0 20px;

  div {
    display: inline-block;

    span {
      background-color: rgba(0, 0, 0, .6);
      border-radius: 3px;
      color: white;
      float: left;
      margin: 4px 2px;
      padding: 7px;
      cursor: pointer;

      &:hover, &.active {
        color: $green;
        background-color: rgba(0, 0, 0, .8);
      }
    }
  }
}