* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body, #root {
  font-family: $JosefinSans-Medium;
  background-color: $bg-color;
}

li {
  list-style-type: none;
}

.container {
  position: relative;
  width: 80%;
  margin: auto;

  @media screen and (max-width: 1255px) {
    width: 95%;
  }
}

.bg-image {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-attachment: fixed;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  transform: scale(1.1);
}

.bg-side-image {
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  filter: contrast(50%);
  -webkit-filter: contrast(50%);
  opacity: .5;
}

.bg-text {
  position: relative;
}

.bg-blur {
  background-color: rgba(0, 0, 0, .8);
}

.bg-darker {
  background-color: $bg-darker !important;
}

.flex-box {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
}

.synopsis {
  font-family: $Calibri-Light;
  margin-top: 10px;
  line-height: 20px;
  text-align: justify;
}

.overflow-hidden {
  overflow: hidden;
}

.absolute-top-full-width {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.black-screen {
  visibility: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 2;
}


.highlight-error {
  label {
    color: white !important;
  }

  fieldset, .MuiButton-outlined {
    border-color: red !important;
  }
}

.hide {
  display: none !important;
}

.align-left {
  margin-left: auto;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.align-self-item {
  align-self: center;
}

.img-preview {
  width: 140px;
  height: 60px;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }
}

.about-anime {
  line-height: 1.5em;
  margin-left: 7px;
  font-family: $JosefinSans-Light;
  color: white;
}

.no-result {
  text-align: center;
  color: white;
  padding: 50px 0;
  background-color: rgba(0, 0, 0, .5);

  h1 {
    text-transform: uppercase;
    font-size: 2em;

    b {
      color: $green;
    }
  }

  span {
    font-size: 1em;
  }
}

.split-content {
  display: flex;
  color: white;
  margin-top: 60px;

  .left {
    flex: 1;

    .content-left {
      width: 408px;
      margin: auto;

      .display-img {
        display: block;
        border-radius: 31px;
        margin: auto;
        height: 576px;
        width: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .genres {
        text-align: center;
        margin-top: 20px;

        div {
          display: inline-block;
          margin-bottom: 6px;
          margin-right: 3px;
          margin-left: 3px;

          span {
            display: inline-block;
            background-color: white;
            color: black;
            padding: 7px 10px;
            cursor: pointer;
            border-radius: 4px;

            &:hover {
              background-color: $green;
              color: white;
            }
          }
        }
      }
    }
  }

  .right {
    flex: 1.2;
    padding-right: 60px;

    h1 {
      font-family: $JosefinSans-Bold;
      font-size: 30px;
      margin-bottom: 30px;
    }

    .content-right {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px;
      grid-auto-rows: minmax(50px, auto);
      margin-top: 60px;

      .bloc-title {
        grid-column: span 2;
        background-color: $bg-medium;
        padding: 20px;
        text-align: center;

        &:nth-last-child(1):nth-child(odd) {
          grid-column: 2 / span 2;
        }

        h3 {
          color: $green;
          margin-bottom: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 1100px) {
    display: block;

    .right {
      padding: 60px;
    }
  }

  @media screen and (max-width: 800px) {
    display: block;

    .right {
      .content-right {
        margin-top: 30px;
        grid-gap: 10px;

        .bloc-title {
          text-align: left;
          padding: 20px 10px;

          &:nth-last-child(1):nth-child(odd) {
            grid-column: 1 / span 4;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    font-size: .8em;

    .right {
      margin-top: 10px;
      padding: 20px;

      h1 {
        text-align: center;
      }

      .content-right {
        margin-top: 30px;
        grid-gap: 10px;

        .bloc-title {
          padding: 20px 10px;

          h3 {
            text-align: left;
            font-size: .9em;
          }

          span {
            font-size: .8em;
          }
        }
      }
    }

    .left .content-left {
      width: auto;

      .display-img {
        height: 400px;
        width: 300px;
      }
    }
  }
}