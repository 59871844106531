#container-animes {
  margin: 20px auto;

  #animes-list {
    width: 100%;
  }

  .aside-content {
    margin-left: 10px;
    color: white;

    p {
      background-color: $bg-darker;
      text-align: center;
      padding: 15px 0;
    }

    .grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .grid-item {
        position: relative;
        width: 240px;
        height: 80px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        span {
          background: -webkit-linear-gradient(right, #000, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 46%, rgba(0, 0, 0, .73) 82%, rgba(0, 0, 0, .84));
          background: linear-gradient(270deg, #000, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 46%, rgba(0, 0, 0, .73) 82%, rgba(0, 0, 0, .84));
          overflow: hidden;
          padding: 0 30px 0 14px;
          position: absolute;
          text-overflow: ellipsis;
          text-shadow: 0 0 6px #000;
          -webkit-transition: all .2s;
          transition: all .2s;
          white-space: nowrap;
          width: 100%;
          height: 80px;
          line-height: 80px;
          cursor: pointer;

          &:hover {
            background: -webkit-linear-gradient(right, #000, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 46%, rgba(0, 0, 0, .77) 82%, rgba(0, 0, 0, .88));
            background: linear-gradient(270deg, #000, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 46%, rgba(0, 0, 0, .77) 82%, rgba(0, 0, 0, .88));
            color: $green;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  #container-animes #animes-list {
    font-size: .8em;
  }

  #container-animes .aside-content {
    overflow: auto;
    background: hsla(0, 1%, 100%, .2);
    padding: 7px;
    position: fixed;
    right: -110%;
    top: 0;
    bottom: 0;
    z-index: 3;
    transition: all ease-in .8s;
    max-width: 90%;

    .grid-container {
      grid-template-columns: 50% 50%;

      .grid-item {
        width: 100%;
      }
    }
  }
}