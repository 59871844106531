.searchbar {
  margin: auto;
  position: relative;
  display: inline-flex;
  max-width: 90%;

  input {
    padding: 5px 7px;
    height: 40px;
    width: 25em;
    transition: width ease-in .6s;
    border: none;
  }

  .clear-search-icon {
    position: absolute;
    right: 40px;
    color: #535353;
    margin-right: 10px;
    cursor: pointer;
    line-height: 45px;

    &:hover {
      color: black;
    }
  }

  .search-icon {
    display: inline-block;
    background-color: $green;
    min-width: 40px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    text-align: center;

    &:hover {
      background-color: $green-darker;
    }

    svg {
      color: white;
    }
  }

  #search-results {
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    z-index: 9;
    background-color: $bg-color;
    max-height: 50vh;
    overflow: auto;
    scrollbar-color: $green $bg-darker;

    .search-item {
      background-color: rgba(0, 0, 0, .28);
      align-items: center;
      margin: 2px auto;
      cursor: pointer;
      transition: all .2s;

      .about-anime {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
        margin-right: 7px;

        h4 {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, .32);

        h4 {
          color: $green;
        }
      }
    }
  }

  @media screen and (max-width: 820px) {
    svg {
      font-size: 1em;
    }

    input::placeholder {
      font-size: .9em;
    }
  }

  @media screen and (max-width: 706px) {
    margin-top: 10px;
  }
}