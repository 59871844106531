#login-form {
  margin-top: 30px;
  text-align: center;

  form {
    width: 380px;
    max-width: 95%;
    padding: 20px;
    display: inline-block;
    background-color: white;
    border: 2px solid $green-darker;

    h1 {
      text-align: left;
    }

    .input-group {
      margin-top: 30px;

      label {
        text-align: left;
        display: block;
        width: 100%;
      }

      input {
        padding-left: 5px;
      }

      input, button {
        width: 100%;
        height: 30px;
      }

      button {
        font-weight: bold;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        background: linear-gradient($green-darker, $green);
        color: white;

        &:hover {
          background: linear-gradient($green, $green-darker);
        }
      }

      .password-input {
        display: flex;
        align-items: center;

        svg {
          color: $green;
          cursor: pointer;
          border: 1px solid $green;
          padding: 6px;
          border-radius: 3px;
          margin-left: -1px;
        }
      }
    }

    .anima-logo {
      margin: 40px auto;
      display: flex;
      justify-content: center;
      height: 100px;

      img {
        height: 100%;
        width: auto;
      }

      span {
        font-family: $JosefinSans-Bold;
        font-size: 3em;
        line-height: 120px;
      }
    }
  }

  .error-message {
    display: flex;
    margin: 0 auto 10px auto;
    padding: 20px;
    width: 380px;
    max-width: 95%;
    background-color: white;
    border: 2px solid $red-error;
    align-items: center;
    gap: 10px;

    img {
      height: 50px;
      width: auto;
    }

    p, span {
      text-align: left;
    }

    p {
      font-size: 1.1em;
      font-family: $JosefinSans-SemiBold;
      color: $red-error;
      margin-bottom: 7px;
    }

    span {
      font-size: .9em;
    }
  }
}
