#ajouter-anime {
  margin-top: 10px;
  background-color: $bg-color;

  #anime-form {
    text-align: center;
    padding-bottom: 10px;

    .child {
      margin: 10px;
    }

    // For Firefox
    input[type=number] {
      -moz-appearance: textfield;
    }

    // For Chrome
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input, label, svg, .MuiSelect-select, .MuiButton-outlined {
      font-size: .9em;
      color: white;

      span {
        color: red;
      }
    }

    fieldset, .MuiButton-outlined {
      border-color: white;
    }

    .Mui-focused {
      color: $green-acid;

      .MuiOutlinedInput-notchedOutline {
        border-color: $green-acid;
      }
    }

    .submit-button {
      margin: auto;
      background-color: $green;
      color: white;

      &:hover {
        background-color: $green-darker;
      }
    }

    //#file-upload {
    //  height: initial;
    //  text-align: center;
    //  padding: 0;
    //  border: none;
    //
    //  #input-file-upload {
    //    display: none;
    //  }
    //
    //  #label-file-upload {
    //    height: 100%;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    border-width: 2px;
    //    border-radius: 1rem;
    //    border-style: dashed;
    //    border-color: #cbd5e1;
    //    background-color: white;
    //    background-repeat: no-repeat;
    //    background-position: top center;
    //    background-size: cover;
    //
    //    span {
    //      display: block;
    //      color: black;
    //    }
    //
    //    button  {
    //      font-family: $JosefinSans-Medium;
    //      cursor: pointer;
    //      margin-top: 10px;
    //      border: none;
    //      background-color: transparent;
    //
    //      &:hover {
    //        text-decoration-line: underline;
    //      }
    //    }
    //
    //    .outline span,
    //    .outline button {
    //      color: white;
    //      text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
    //    }
    //
    //    &.drag-active {
    //      border-color: $green;
    //    }
    //  }
    //
    //  #drag-file-element {
    //    position: absolute;
    //    width: 100%;
    //    height: 100%;
    //    border-radius: 1rem;
    //    top: 0;
    //    right: 0;
    //    bottom: 0;
    //    left: 0;
    //  }
    //}
  }
}