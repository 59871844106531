#zone-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 8;
  background-color: rgba(0, 0, 0, .5);

  .success-modal, .error-modal {
    font-family: $Arial;
    font-size: .9em;
    position: fixed;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    z-index: 9;
  }

  .success-modal #header,
  .error-modal #header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    color: white;

    h3 {
      margin-top: 4px;
    }

    span {
      padding: 1px 3px;
      border-radius: 2px;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, .3);
      }
    }
  }

  .success-modal #body,
  .error-modal #body {
    background-color: white;
    overflow: hidden;

    p {
      padding: 15px 20px;

      ul li {
        list-style-type: disc;
        margin-left: 3em;
        margin-top: 13px;
      }
    }

    hr {
      border: 1px solid #f3f3f3;
    }

    button {
      cursor: pointer;
      float: right;
      border: none;
      padding: 10px 15px;
      margin: 10px;
    }
  }

  .success-modal {
    #header {
      background-color: $header-succes-modal;
    }

    #body button {
      color: white;
      background-color: $header-error-modal;

      &:hover {
        background-color: $header-error-modal_hover;
      }
    }
  }

  .error-modal {
    #header {
      background-color: $header-error-modal;
    }

    #body button {
      background-color: $grey-button-modal;

      &:hover {
        background-color: $grey-button-modal_hover;
      }
    }
  }
}