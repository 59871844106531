@font-face {
  font-family: "josefin-sans-medium";
  src: url("../assets/fonts/JosefinSans-Medium.ttf");
}

@font-face {
  font-family: "josefin-sans-light";
  src: url("../assets/fonts/JosefinSans-Light.ttf");
}

@font-face {
  font-family: "josefin-sans-regular";
  src: url("../assets/fonts/JosefinSans-Regular.ttf");
}

@font-face {
  font-family: "josefin-sans-bold";
  src: url("../assets/fonts/JosefinSans-Bold.ttf");
}

@font-face {
  font-family: "josefin-sans-semi-bold";
  src: url("../assets/fonts/JosefinSans-SemiBold.ttf");
}


@font-face {
  font-family: "calibri-regular";
  src: url("../assets/fonts/Calibri-Regular.ttf");
}

@font-face {
  font-family: "calibri-light";
  src: url("../assets/fonts/Calibri-Light.ttf");
}