#container-manga {
  #adaptation {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  hr {
    margin: 50px auto;
    width: 90%;
  }

  #tomes {
    padding: 0 30px;
    margin-bottom: 50px;
    text-align: center;

    div {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      h1 {
        flex: 1;
        display: inline-block;
        font-family: $JosefinSans-Bold;
        font-size: 30px;
        color: $green;
        text-align: left;
      }

      span {
        display: block;
        cursor: pointer;
        font-size: .8em;
        color: white;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    img {
      cursor: pointer;
      margin: 10px 5px;
      height: 200px;
      width: auto;
      max-width: 130px;
      transition: transform .2s;

      &:hover {
        transform: scale(1.3);
      }

      &.active {
        box-shadow: 0 0 16px 0 $green-darker;
      }
    }

    @media screen and (max-width: 500px) {
      div {
        display: block;

        span {
          margin-top: 10px;
        }
      }
    }
  }
}