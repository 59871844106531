.navigation {
  display: inline-block;
  width: 100%;
  background-color: $bg-darker;

  ul {
    display: flex;
    align-items: center;
    padding: 15px 30px;

    li {
      transition: 0.2s;
      cursor: pointer;
      padding: 10px;

      a, button {
        font-family: $JosefinSans-SemiBold;
        font-size: 17px;
        color: white;
        text-decoration: none;

        &:hover {
          color: $green;
        }
      }

      a {
        padding-bottom: 7px;
      }

      button {
        cursor: pointer;
        border: none;
        background-color: transparent;
      }
    }

    .nav-active {
      position: relative;
      color: $green;

      &::after {
        content: "";
        height: 4px;;
        width: 0;
        background: $green;
        position: absolute;
        border-radius: 10px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        -webkit-animation: anim 0.3s ease forwards;
        animation: anim 0.6s ease forwards;

        @-webkit-keyframes anim {
          to {
            width: 10%;
          }
        }
        @keyframes anim {
          to {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .navigation {
    text-align: center;

    ul {
      padding: 15px 10px;
      display: block;

      li {
        display: inline-block;

        a {
          font-size: .9em;
        }
      }
    }
  }
}

@media screen and (max-width: 776px) {
  #login-link, #logout-link {
    margin-top: 10px;
    margin-left: 10px;
  }
}