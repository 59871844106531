#upload-button {
  display: flex;
  text-transform: none;
  min-width: 0;

  &.isUpload {
    span svg {
      color: $green-acid !important;
    }
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}