#preferences {
  margin-top: 10px;
  background-color: $bg-color;

  #container-tri, #container-filtre {
    padding: 2px;

    label, select {
      font-size: .9em;
      padding: 9px 5px;
      margin: 2px auto;
      border-radius: 4px;
      border: 1px solid $bg-medium;
    }
  }

  #container-tri {
    padding-right: 1px;

    label {
      display: block;
      background-color: rgba(0, 0, 0, .28);
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, .38);
        color: $green;
      }
    }

    input[type='radio'] {
      accent-color: $green;
      display: none;

      &:checked ~ label {
        background-color: rgba(0, 0, 0, .38);
        color: $green;
      }
    }
  }

  #container-filtre {
    padding-left: 1px;

    select {
      font-family: $JosefinSans-Medium;
      width: 100%;

      &:first-child {
        margin-bottom: 0;
      }

      &:focus {
        border: 1px solid $green;
      }
    }

    button {
      float: right;
      margin-top: 5px;
      font-size: 11px;
      padding: 5px 7px;
      border-radius: 4px;
      border: 1px solid $bg-medium;
      cursor: pointer;
      background-color: $green;
      color: white;

      &:hover {
        background-color: $green-darker;
      }
    }
  }
}