#container-genres {
  display: inline-block;
  width: 100%;

  .grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 16px;

    .grid-item {
      background-color: rgba(0, 0, 0, .28);
      border: 2px solid black;
      border-radius: 3px;
      padding: 10px 18px;
      cursor: pointer;
      text-align: justify;
      height: 20em;
      transition: all .2s;

      h3 {
        text-align: center;
        color: $green;
        margin-bottom: 14px;
      }

      p {
        font-family: $Calibri-Light;
        line-height: 1.3em;
        display: -webkit-box;
        -webkit-line-clamp: 12;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: white;

        b {
          font-family: $Arial;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, .32);
      }
    }

    @media screen and (max-width: 2000px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 1600px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
}