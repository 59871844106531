#listOfAnimes {
  background-color: $bg-color;

  #content-anime {
    margin: 2px auto;
    align-items: center;
    background-color: rgba(0, 0, 0, .28);
    cursor: pointer;
    transition: all .2s;

    &:hover {
      background-color: rgba(0, 0, 0, .32);

      h4 {
        color: $green;
      }
    }
  }
}