#container-genre {
  margin: 30px;

  #details-genre {
    background-color: rgba(0, 0, 0, .28);
    padding: 2em 5em;
    text-align: center;
    margin-bottom: 30px;
    color: white;

    h1 {
      font-size: 32px;
      margin-bottom: 10px;
      color: $green;
    }

    p {
      font-family: $Calibri-Light;

      b {
        font-family: $Arial;
      }
    }

    @media screen and (max-width: 900px) {
      padding: 2em 30px;

      p {
        text-align: justify;
      }
    }

    @media screen and (max-width: 600px) {
      padding: 2em 10px;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;

    .grid-item {
      background-color: rgba(0, 0, 0, .28);
      color: white;
      border: 2px solid black;
      border-radius: 3px;
      padding: 4px;
      cursor: pointer;
      text-align: justify;
      transition: .5s;

      img {
        width: 100%;
        height: auto;
      }

      h3 {
        text-align: center;
        transition: .5s;
        margin-top: 15px;
      }

      p {
        font-family: $Calibri-Light;
        line-height: 1.3em;
        overflow: hidden;
        padding: 15px;
      }

      &:hover {
        border: 2px solid $green-darker;
        box-shadow: 0 0 16px 0 $green-darker;

        h3 {
          color: $green;
        }
      }
    }

    @media screen and (max-width: 2000px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 1600px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  @media screen and (max-width: 1255px) {
    font-size: .8em;
    margin: 10px;
  }
}