#loading-screen {
  text-align: center;
  font-size: 2em;
  color: white;
  width: 100%;

  &.full-screen-icon {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}